import React, { lazy } from 'react'

const GeneralDocument = lazy(() =>
  import('./GeneralDocument/GeneralDocumentListing').then(module => ({
    default: module.GeneralDocumentListing,
  }))
)

const GeneralDocumentFolder = lazy(() =>
  import('./GeneralDocument/GeneralDocumentFolder').then(module => ({
    default: module.GeneralDocumentFolder,
  }))
)

const PayslipListing = lazy(() =>
  import('./Payslip/PayslipListing').then(module => ({
    default: module.PayslipListing,
  }))
)

const PayslipView = lazy(() =>
  import('./Payslip/PaySlipView').then(module => ({
    default: module.PayslipView,
  }))
)

const EAFormListing = lazy(() =>
  import('./EAForm/EAFormListing').then(module => ({
    default: module.EAFormListing,
  }))
)

const EAFormView = lazy(() =>
  import('./EAForm/EAFormView').then(module => ({
    default: module.EAFormView,
  }))
)
const ContractInfoListing = lazy(() =>
  import('./ContractInfo/ContractInfoListing').then(module => ({
    default: module.ContractInfoListing,
  }))
)
const ContractInfoView = lazy(() =>
  import('./ContractInfo/ContractInfoView').then(module => ({
    default: module.ContractInfoView,
  }))
)
const ContractInfoForm = lazy(() =>
  import('./ContractInfo/ContractInfoForm').then(module => ({
    default: module.ContractInfoForm,
  }))
)

const ReportTemplateListing = lazy(() =>
  import('./ELetter/ReportTempListing').then(module => ({
    default: module.ReportTemplateListing,
  }))
)
const PayslipDocumentsListing = lazy(() =>
  import('./PayslipDocuments/PayslipDocumentsListing').then(module => ({
    default: module.PayslipDocumentsListing,
  }))
)
const EAFormDocumentsListing = lazy(() =>
  import('./EAFormDocuments/EAFormDocumentsListing').then(module => ({
    default: module.EAFormDocumentsListing,
  }))
)
const EAFormDocumentsView = lazy(() =>
  import('./EAFormDocuments/EAFormDocumentsView').then(module => ({
    default: module.EAFormDocumentsView,
  }))
)
const PayslipDocumentsView = lazy(() =>
  import('./PayslipDocuments/PayslipDocumentsView').then(module => ({
    default: module.PayslipDocumentsView,
  }))
)
const DigitalDocumentRoutes = [
  {
    props: { exact: true, path: '/PersonnelManagement/Employee/doc/general' },
    component: <GeneralDocument />,
  },

  {
    props: {
      exact: true,
      path: '/PersonnelManagement/Employee/doc/general/folder',
    },
    component: <GeneralDocumentFolder />,
  },

  {
    props: {
      exact: true,
      path: '/PersonnelManagement/Employee/doc/contractinfo',
    },
    component: <ContractInfoListing />,
  },
  {
    props: { exact: true, path: '/Employee/doc/contractInfo/contractinfolist' },
    component: <ContractInfoView />,
  },
  {
    props: {
      exact: true,
      path: '/PersonnelManagement/Employee/doc/contractInfo/contractinfoForm',
    },
    component: <ContractInfoForm />,
  },
  {
    props: { exact: true, path: '/PersonnelManagement/Payslip/PayslipListing' },
    component: <PayslipListing />,
  },
  {
    props: {
      exact: true,
      path: '/PersonnelManagement/Employee/doc/payslip/paysliplist',
    },
    component: <PayslipView />,
  },
  {
    props: { exact: true, path: '/PersonnelManagement/EAForm/EAFormListing' },
    component: <EAFormListing />,
  },
  {
    props: { exact: true, path: '/PersonnelManagement/EAForm/EAFormView' },
    component: <EAFormView />,
  },
  {
    props: { exact: true, path: '/PersonnelManagement/Employee/doc/eLetter' },
    component: <ReportTemplateListing />,
  },
  {
    props: {
      exact: true,
      path: '/PersonnelManagement/PayslipDocument/PayslipDocumentListing',
    },
    component: <PayslipDocumentsListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/PersonnelManagement/Employee/doc/payslipdocs/payslipdocumentslist',
    },
    component: <PayslipDocumentsView />,
  },
  {
    props: {
      exact: true,
      path: '/PersonnelManagement/EAFormDocument/EAFormDocumentListing',
    },
    component: <EAFormDocumentsListing />,
  },
  {
    props: {
      exact: true,
      path: '/PersonnelManagement/EAFormDocument/EAFormDocumentView',
    },
    component: <EAFormDocumentsView />,
  },
]

export default DigitalDocumentRoutes
